import { FC } from "react"

const RubbleIcon: FC = () => {
  return (
    <>
      <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.425 18V0.149999H7.125C9.30833 0.149999 10.925 0.591666 11.975 1.475C13.0417 2.35833 13.575 3.64167 13.575 5.325C13.575 6.45833 13.3167 7.43333 12.8 8.25C12.2833 9.05 11.5167 9.66667 10.5 10.1C9.5 10.5333 8.25833 10.75 6.775 10.75H4.675V18H2.425ZM0.25 14.6V12.975H8.8V14.6H0.25ZM0.25 10.75V8.85H5.975V10.75H0.25ZM6.45 8.85C7.46667 8.85 8.325 8.74167 9.025 8.525C9.74167 8.30833 10.2917 7.95 10.675 7.45C11.0583 6.95 11.25 6.26667 11.25 5.4C11.25 4.26667 10.9 3.425 10.2 2.875C9.5 2.325 8.40833 2.05 6.925 2.05H4.675V8.85H6.45Z" fill="white"/>
      </svg>
    </>
  )
}

export default RubbleIcon