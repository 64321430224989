export function getOrderStatus(status: number) {
  switch (status) {
    case 1:
      return "Принят" // Клиент создал заказ
    case 2:
      return "Собираем заказ" // Заказ собирается
    case 3:
      return "Заказ собран" // Заказ собран
    case 4:
      return "Курьер в пути" // Курьер в пути
    case 5:
      return "Доставлен" // Доставлен курьером
    case 6:
      return "Готов к получению" // Если самовывоз из магазина
    case 7:
      return "Завершен"
    case 8:
      return "Отменен" // Отменен пользователем
    case 9:
      return "Удален" // Отменен администратором
    default:
      return "В обработке"
  }
}

export function getCompound(comp: string) {
  return comp == "item" ? "шт." : "100 г."
}