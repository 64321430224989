import React, {createContext} from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import reportWebVitals from './reportWebVitals'

import Store from './core/store'
import AdminStore from './core/store/admin'
import ShopStore from './core/store/shop'
import PagesStore from './core/store/pages'

interface State {
  store: Store
  adminStore: AdminStore
  shopStore: ShopStore
  pagesStore: PagesStore
}

const store = new Store()
const adminStore = new AdminStore()
const shopStore = new ShopStore()
const pagesStore = new PagesStore()

export const Context = createContext<State>({store, adminStore, shopStore, pagesStore})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <Context.Provider value={{store, adminStore, shopStore, pagesStore}}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Context.Provider>
  </React.StrictMode>
)

reportWebVitals()
