import { FC } from "react"

import styles from "./XForm.module.scss"

export interface XFlexedProps {
  children: any
  wrap?: boolean
  direction?: "row" | "column"
  maxWidth?: string
}

const XFlexed: FC<XFlexedProps> = ({
  children, wrap = false, direction = "row", maxWidth = "auto"
}) => {
  return (
    <>
      <div className={styles.flexed} style={{
        flexDirection: direction, maxWidth: maxWidth
      }}>
        {children}
      </div>
    </>
  )
}

export default XFlexed