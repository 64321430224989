import React from "react";

import Home from "../../pages/Home"
import Policy from "../../pages/Policy"

const StoreCatalog = React.lazy(() => import("../../pages/Store/Store"))
const StoreProduct = React.lazy(() => import("../../pages/Store/StoreProduct"))

const Blog = React.lazy(() => import("../../pages/Blog/Blog"))
const BlogPost = React.lazy(() => import("../../pages/Blog/BlogPost"))
const Delivery = React.lazy(() => import("../../pages/About/Delivery"))
const About = React.lazy(() => import("../../pages/About/About"))

const Brands = React.lazy(() => import("../../pages/Brands/Brands"))
const BrandPage = React.lazy(() => import("../../pages/Brands/BrandPage"))

const Basket = React.lazy(() => import("../../pages/Store/Basket"))
const SignIn = React.lazy(() => import("../../pages/Account/SignIn"))
const Account = React.lazy(() => import("../../pages/Account/Account"))

const AdminAuth = React.lazy(() => import("../../pages/_Admin/AdminAuth"))
const Admin = React.lazy(() => import("../../pages/_Admin/Admin"))

export interface IRoute {
  path: string;
  component: React.ReactNode;
  exact?: boolean;
}

export enum RouteNames {
  HOME = '/',
  POLICY = '/policy',

  ABOUT = '/about',
  DELIVERY = '/delivery',
  
  BLOG = '/blog',
  BLOG_PAGE = '/blog/:name',

  CATALOG = '/catalog',
  CATALOG_PRODUCT = "/catalog/:name",

  BASKET = "/basket",
  ACCOUNT_SIGN = "/signin",
  ACCOUNT = "/account",
  ACCOUNT_PAGE = "/account/:page",
  // ACCOUNT_FAVORITES = "/account/favorites",
  // ACCOUNT_ORDERS = "/account/orders",

  BRANDS = "/brands",
  BRAND_PAGE = "/brands/:name",

  ADMIN_AUTH = "/admin/auth",
  ADMIN_HOME = "/admin",
  ADMIN_STATS = "/admin/analytics",
  ADMIN_CLIENTS = "/admin/clients",
  ADMIN_CLIENTS_ID = "/admin/clients/:id",
  ADMIN_CATALOG = "/admin/catalog",
  ADMIN_CATALOG_ID = "/admin/catalog/:id",
  ADMIN_CATS = "/admin/categories",
  ADMIN_CATS_ID = "/admin/categories/:id",
  ADMIN_BLOG = "/admin/blog",
  ADMIN_BLOG_ID = "/admin/blog/:id",
  ADMIN_BRANDS = "/admin/brands",
  ADMIN_BRANDS_ID = "/admin/brands/:id",
  ADMIN_ORDERS = "/admin/orders",
  ADMIN_ORDERS_ID = "/admin/orders/:id",
  ADMIN_CITIES = "/admin/cities",
  ADMIN_CITIES_ID = "/admin/cities/:id",
  ADMIN_SHOPS = "/admin/shops",
  ADMIN_SHOPS_ID = "/admin/shops/:id",
  ADMIN_SETTINGS = "/admin/settings"
}

export const publicRoutes: IRoute[ ] = [
  { path: RouteNames.HOME, exact: true, component: <Home /> },
  { path: RouteNames.POLICY, exact: true, component: <Policy /> },

  { path: RouteNames.ABOUT, exact: true, component: <About /> },
  { path: RouteNames.DELIVERY, exact: true, component: <Delivery /> },
  { path: RouteNames.BLOG, exact: true, component: <Blog /> },
  { path: RouteNames.BLOG_PAGE, exact: true, component: <BlogPost /> },
  
  { path: RouteNames.CATALOG, exact: true, component: <StoreCatalog /> },
  { path: RouteNames.CATALOG_PRODUCT, exact: true, component: <StoreCatalog /> },

  { path: RouteNames.BRANDS, exact: true, component: <Brands /> },
  { path: RouteNames.BRAND_PAGE, exact: true, component: <BrandPage /> },
  
  { path: RouteNames.BASKET, exact: true, component: <Basket /> },

  { path: RouteNames.ACCOUNT_SIGN, exact: true, component: <SignIn /> },

  { path: RouteNames.ACCOUNT, exact: true, component: <Account /> },
  { path: RouteNames.ACCOUNT_PAGE, exact: true, component: <Account /> },

  { path: RouteNames.ADMIN_AUTH, exact: true, component: <AdminAuth /> },
  { path: RouteNames.ADMIN_HOME, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_STATS, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_CLIENTS, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_CLIENTS_ID, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_CATALOG, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_CATALOG_ID, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_CATS, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_CATS_ID, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_BLOG, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_BLOG_ID, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_BRANDS, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_BRANDS_ID, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_ORDERS, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_ORDERS_ID, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_CITIES, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_CITIES_ID, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_SHOPS, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_SHOPS_ID, exact: true, component: <Admin /> },
  { path: RouteNames.ADMIN_SETTINGS, exact: true, component: <Admin /> }
]
