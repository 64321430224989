import { FC, useContext, useEffect } from 'react'
import { observer } from "mobx-react-lite"
import { Context } from '.'
import { useLocation } from 'react-router-dom'
import useWindowDimensions from './core/hooks/useWindowDimensions'
import AppRouter from './components/AppRouter'

import { useGeolocated } from "react-geolocated"

import './styles/App.scss';
import Footer from './components/Footer'
import Header from './components/Header'
import { useFetching } from './core/hooks/useFetching'
import { ApiService } from './core/api/api'
import StoreProductCard from './pages/Store/components/StoreProductCard'
import BasketView from './pages/_shared/BasketView/BasketView'
import {isNullOrUndefined} from './core/utils/utils'
import OptionsModal from './pages/_shared/OptionsModal/OptionsModal'
import {StoreOrder} from './core/models/store/order'

declare const ymaps: any

const App: FC = () => {

  const { store, shopStore, adminStore } = useContext(Context)

  const location = useLocation()
  
  const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  })
  
  const { width, height } = useWindowDimensions()

  const [fetchSettings] = useFetching(async () => {
    const res = await ApiService.core.getSettings()

    let techworks = res.data.settings.find((v: any) => v.name == "techworks")

    if (techworks != undefined) {
      store.setTechworksState(techworks.data.state)
      store.setTechworksSetting(techworks)
    }

    store.setIsSettingsPreloaded(true)
    setTimeout(() => {
      store.setIsSettingsLoaded(true)
    }, 1000)
  })

  const [fetchAccount] = useFetching(async () => {
    const authed = store.checkAuth()

    if (authed) {
      try {
        const res = await ApiService.users.getAccount()
        const resOrders = await ApiService.store.getOrders()
      
        let ordrs: any[] = resOrders.data.orders.sort((a: StoreOrder, b: StoreOrder) => {
          return a.status > b.status ? 1 : (a.status < b.status ? -1 : 0)
        })
        shopStore.setOrders(ordrs)

        console.log("account", res.data, resOrders.data)
        if (res.data.state == 1) {
          store.setupAuth(res.data.user.token, res.data.user)
        }
      } catch (err) {
        console.log("err", err)
      }
    }
  })

  const [fetchBasket] = useFetching(async () => {
    const res = await ApiService.store.getBasket()
    shopStore.setGoods(res.data.goods)
  })

  const [fetchTimezones] = useFetching(async () => {
    const res = await ApiService.store.getTimezones(1)
    let tzs: any[] = res.data.timezones.sort((a: any, b: any) => {
      return a.order > b.order ? 1 : (a.order < b.order ? -1 : 0)
    })
    shopStore.setTimezones(tzs)
  })

  const [fetchCities] = useFetching(async () => {
    const citiesRes = await ApiService.store.getCities()

    let cities: any[] = citiesRes.data.cities.sort((a: any, b: any) => {
      return a.order > b.order ? 1 : (a.order < b.order ? -1 : 0)
    })

    shopStore.setCities(cities)

    if (isGeolocationEnabled && coords != undefined) {
      console.log("Geolocation enabled! Search user city...", coords)

      const geocoder = ymaps.geocode([coords.latitude, coords.longitude])

      geocoder.then((res: any) => {
        console.log(res)

        const cityChilds = res.geoObjects._collectionComponent._baseArrayComponent._children  
        if (!isNullOrUndefined(cityChilds) && cityChilds.length > 0) {
          const cityContain = cityChilds[0].properties._data.text
          let exists: any | null = null
          cities.forEach((city) => {
            if (cityContain.toLowerCase().includes(city.name.toLowerCase())) {
              exists = city
            }
          })
          if (exists == null) {
            shopStore.setSelectedCity(cities[0])
            shopStore.setSelectedCityName(cities[0].name)
          } else {
            shopStore.setSelectedCity(exists)
            shopStore.setSelectedCityName(exists.name)

            shopStore.openCityQuest()
          }
        }
      }, (err: any) => {
        console.log(err)
      })
    } else {
      shopStore.setSelectedCity(cities[0])
      shopStore.setSelectedCityName(cities[0].name)
    }
  })

  const [fetchStore] = useFetching(async () => {
    const res = await ApiService.store.getProducts({
      search: shopStore.globalSearchText,
      sort: shopStore.sort,
      sortBy: shopStore.sortBy,
      page: shopStore.pageIndex,
      limit: shopStore.pageLimit,
      only_active: "1",
      category: ""
    })

    shopStore.setTotalProducts(res.data.total)
    shopStore.setProducts(res.data.products)
  })

  const handleLeftPosition = () => {
    const cardwrap = document.getElementById("cardwrap")

    if (cardwrap != null) {
      console.log(cardwrap.offsetLeft)
      if (width > 960) {
        shopStore.setLeftPos(`${cardwrap.offsetLeft - 1}px`)
      } else if (width > 768) {
        shopStore.setLeftPos(`${(cardwrap.clientWidth / 2) - 190}px`)
      } else {
        shopStore.setLeftPos(``)
      }
    }
  }

  const isBasketVisible = () => {
    return !location.pathname.includes("/blog") && !location.pathname.includes("/about") && !location.pathname.includes("/brands") && location.pathname != "/"
  }

  useEffect(() => {
    handleLeftPosition()
  }, [width])

  useEffect(() => {
    console.log(isGeolocationAvailable)
    console.log(isGeolocationEnabled)
    console.log(coords)
    if (coords != undefined) {
      fetchCities()
    }
  }, [coords])

  useEffect(() => {
    if (width <= 768) {
      store.setIsMobile(true)
    } else {
      store.setIsMobile(false)
    }
  }, [width, height])

  useEffect(() => {
    if (width <= 768) {
      store.setIsMobile(true);
    } else {
      store.setIsMobile(false);
    }

    handleLeftPosition()

    setTimeout(() => {
      handleLeftPosition()
      fetchCities()
    }, 1000)

    fetchAccount()
    
    fetchTimezones()
    fetchSettings()
    fetchStore()
  }, [])

  return (
    <>
      <div className="xfader" style={{opacity: store.isSettingsLoaded ? "1" : "0"}}>

        {store.isSettingsPreloaded ? <>
        
          {store.techworksState && location.pathname != "/admin/auth" && (!store.isAuth || (store.isAuth && store.userData.role != "ADMIN")) ? <>
            <div className={"placeholder"}>
              <div className={"ph_wrap"}>
                <img src="/assets/images/logo_w.svg" />

                <p>
                  Скоро здесь можно будет заказать самые вкусные товары!<br/>
                  <a href="https://fermstory-franch.ru">Наша франшиза</a>
                  <a href="https://t.me/fermstory_msk">Наш Telegram</a>
                </p>


                <div className={"yt"}>
                  {/* <iframe 
                    width="100%" 
                    height="100%" 
                    src="https://www.youtube.com/embed/91y21Pck3Ms?si=Luef7_kGHFGLSN8w" 
                    title="YouTube video player" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen></iframe> */}

                  <iframe 
                    width="100%" 
                    height="100%" 
                    src="https://www.youtube.com/embed/9ayPbtK4KGQ?si=hXbgWKGYEy03Qf4-" 
                    title="YouTube video player" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowFullScreen></iframe>
                </div>

              </div>
            </div>
          </> : (location.pathname.includes("/admin") ? <>
            <AppRouter />
          </> : <>
            <Header />
            <AppRouter />

            {/* {!location.pathname.includes("/catalog") ? <Footer /> : <div style={{height: "80px"}}></div>} */}
            <Footer />

            <OptionsModal
              height="auto"
              openned={shopStore.isCityVisible}
              title="Выберите город"
              onClose={() => shopStore.setIsCityVisible(false)}
              done="Выбрать"
              onDone={() => {
                shopStore.setIsCityVisible(false)
              }}>
              <div className="cities_selecting">
                {shopStore.cities.map(city => <div className={"cs_item" + (shopStore.selectedCityName == city.name ? " selected" : "")} onClick={() => {
                    shopStore.setSelectedCity(city)
                    shopStore.setSelectedCityName(city.name)
                  }}>
                  <div className="cs_item__name">{city.name}</div>
                  <div className="cs_item__check"><span><i className="fas fa-check"></i></span></div>
                </div>)}
              </div>
            </OptionsModal>

            {isBasketVisible() ? <BasketView
              onOpenProduct={(product) => {
                shopStore.setSelectedProduct(product)
                shopStore.setSelectedProductId(product.id)

                setTimeout(() => {
                  shopStore.setIsProductOpenned(true)
                  setTimeout(() => {
                    shopStore.setIsProductVisible(true)
                  }, 100)
                }, 100)
              }}
              onOpenCategory={(cat) => {

              }} /> : <></>}

            {shopStore.isProductOpenned ? <StoreProductCard 
              product_id={shopStore.selectedProductId} 
              visible={shopStore.isProductVisible}
              onFetchData={() => {
                fetchBasket()
                fetchStore()
              }}
              onClose={() => {
                shopStore.setIsProductVisible(false)
                setTimeout(() => {
                  shopStore.setIsProductOpenned(false)
                }, 300)
              }} /> : <></>}
          </>)}
        
        </> : <></>}

      </div>
    </>
  )
}

export default observer(App)
