export enum HomeMenuPage {
  NONE = "",
  FEATURES = "features",
  PRODS = "products",
  PRODS_O = "products_one",
  PRODS_TW = "products_two",
  PRODS_T = "products_three",
  USEFULLS = "usefulls",
  EXPIRIENCE = "expirience"
}

export enum ControllerName {
  USERS = "/users",
  PROJECTS = "/projects",
  CORE = "/core",
  CRM = "/crm",
  STORE = "/store",
  BLOG = "/articles",
  BRANDS = "/brands"
}

// export const API_URL = "http://localhost:4249";
export const API_URL = "https://api.fermstory.ru";

export type SliderToggleDirection = "next" | "prev";
export enum SliderToggleDir {
  NEXT = "next",
  PREV = "prev"
}

export enum StorageKeys {
  AUTHED = "nx_fermstory_authed",
  USER = "nx_fermstory_user",
  TOKEN = "nx_fermstory_token",
  GOOGLE_TOKEN = "nx_fermstory_google_token",

  ADMIN_CRM_VIEW_TYPE = "nx_fermstory_crm_view_type",

  CITY_QUEST_SHOWN = "nx_fermstory_city_quest",

  GLOBAL_SEARCH_TEXT = "nx_fermstory_gsearch_text",

  LOCK_SCREEN = "nx_fermstory_lock_screen",

  SETTING_ADMIN_SIDEBAR = "nx_fermstory_sidebar",

  SETTINGS_HOME_IMGS = "nx_fermstory_home_images",
  SETTINGS_CONTACTS = "nx_fermstory_contacts",
  SETTINGS_HSS = "nx_fermstory_hss",
  SETTINGS_TRANS = "nx_fermstory_trans",
  SETTINGS_TRANS_ST = "nx_fermstory_trans_st",
  SETTINGS_FOOTER_DATA = "nx_fermstory_footer_data",
  SETTINGS_ABOUT = "nx_fermstory_about_data",
  SETTINGS_SEO = "nx_fermstory_seo_config",
  SETTINGS_SERVICES = "nx_fermstory_services_config",

  PROJECTS = "nx_fermstory_projects"
}

export const ruPickerLang = {
  today: "Сегодня",
  now: "Сегодня",
  ok: "Выбрать",
  backToToday: "Вернуться к сегодня",
  timeSelect: "Выбрать время",
  dateSelect: "Выбрать дату",
  clear: "Сбросить",
  month: "Месяц",
  year: "Год",
  previousMonth: "Предыдущий месяц",
  nextMonth: "Следующий месяц",
  monthSelect: "Выбрать месяц",
  yearSelect: "Выбрать год",
  decadeSelect: "Выбрать декаду",
  previousYear: "Предыдущий год",
  nextYear: "Следующий год",
  previousDecade: "Предыдущая декада",
  nextDecade: "Следующая декада",
  previousCentury: "Предыдущий век",
  nextCentury: "Следующий век",

  yearFormat: "YYYY",
  dateFormat: "M/D/YYYY",
  dayFormat: "D",
  dateTimeFormat: "M/D/YYYY HH:mm:ss",
  monthFormat: "MMMM",

  shortMonths: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
  shortWeekDays: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"]
}