import { observer } from "mobx-react-lite";
import React, { FC } from "react"
import { Route, Routes } from "react-router-dom"
import { publicRoutes } from "../core/router"

const AppRouter: FC = () => {
  return (
    <Routes>
      {publicRoutes.map(route => 
        <Route
          path={route.path}
          element={route.component}
          key={route.path}
          />
      )}
    </Routes>
  )
}

export default observer(AppRouter)