import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useRef, useState } from "react"
import { Context } from ".."

import useWindowDimensions from "../core/hooks/useWindowDimensions"
import { useFetching } from "../core/hooks/useFetching"

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import {isElementInViewport} from "../core/utils/utils"

import styles from "../styles/Home.module.scss"
import Breadcrumbs from "./_shared/Breadcrumbs/Breadcrumbs"
import CardWrap from "./_shared/CardWrap/CardWrap"
import {ApiService} from "../core/api/api"
import {useLocation} from "react-router-dom"
import {XProduct} from "../core/models/store/product"
import RubbleIcon from "../components/icons/RubbleIcon"

interface IArticle {
  id?: number
  image: string | null
  frame?: string
  title: string
  subtitle: string
  text: string
  source: string | null
  link: string | null
}

const ArticlesData: IArticle[] = []

const ShopsData: any[] = []

const Home: FC = () => {

  const { store, shopStore } = useContext(Context)
  const { width } = useWindowDimensions()

  const location = useLocation()
  
  const swiperEl1 = useRef(null as any)
  const swiperEl2 = useRef(null as any)
  const swiperEl3 = useRef(null as any)

  const [swiper1, setSwiper1] = useState({} as any)
  const [swiper2, setSwiper2] = useState({} as any)
  const [swiper3, setSwiper3] = useState({} as any)

  const [userName, setUserName] = useState("")
  const [userCity, setUserCity] = useState("")
  const [userPhone, setUserPhone] = useState("")

  const [userToken, setUserToken] = useState("")
  
  const [clusterPoints, setClusterPoints] = useState([
    [55.686852, 37.852365], [47.232634, 39.625379], [45.119326, 38.927496], [45.097692, 38.973570]
  ] as number[][])

  const [articles, setArticles] = useState([] as IArticle[])

  const [fetchData, isDataLoading, errorData] = useFetching(async () => {
    let lst: IArticle[] = []

    lst = ArticlesData

    setArticles(prev => prev = lst)
  })
  
  const [fetchStore] = useFetching(async () => {
    const res = await ApiService.store.getProducts({
      search: shopStore.globalSearchText,
      sort: shopStore.sort,
      sortBy: shopStore.sortBy,
      page: shopStore.pageIndex,
      limit: 4,
      category: ""
    })

    console.log(res)

    shopStore.setHomeProducts(res.data.products)
  })

  const runAnimations = () => {
    
  }

  const openForm = (title: string) => {
    store.setFormTitle(title)
    store.setIsFormVisible(true)
  }

  const runOpacity = (el: HTMLElement | null, delay: number = 0, asc: number = 0) => {
    if (el != null) {
      // if (width <= 960) {
      //   asc = -40;
      // }

      if (el.style.opacity == "") {
        el.style.opacity = `0`;
      }

      if (isElementInViewport(el)) {
        let dell = window.innerHeight - ((el.getBoundingClientRect().y + el.getBoundingClientRect().height) - delay + asc);
        let spl = dell / 100;  
        
        if (el.style.opacity == "") {
          el.style.opacity = `${spl}`;
        } else {
          
          el.style.opacity = `${spl}`;
        }
      } else {
        if (parseInt(el.style.opacity) == 0) {
          el.style.opacity = `0`;
        }
      }
    }
  }

  const runBottomOpacity = (el: HTMLElement | null, delay: number = 0, asc: number = 0) => {
    if (el != null) {
      // if (width <= 960) {
      //   delay = 40;
      // }

      if (el.style.transform == "") {
        el.style.transform = "translateY(35px)";
      }

      if (el.style.opacity == "") {
        el.style.opacity = "0";
      }

      if (isElementInViewport(el)) {
        let dell = window.innerHeight - ((el.getBoundingClientRect().y + el.getBoundingClientRect().height) - delay + asc);
        let spl = (dell) / 100;
        if (spl > parseInt(el.style.opacity)) {
        }
        el.style.opacity = `${spl}`;
        
        let ix = ((dell) / 4) - 35;

        if (ix > parseInt(el.style.bottom.replace("px", ""))) {
          
        }
        if (ix < 0) {
          el.style.transform = `translateY(${-ix}px)`;
        } else {
          el.style.transform = `translateY(0px)`;
        }
      } else {
        if (el.style.opacity == "0") {
          el.style.opacity = `0`;
          el.style.transform = "translateY(35px)";
        }
      }
    }
  }
  
  const getFinalPrice = (product: XProduct) => {
    if (product.compound == "item") {
      return product.price
    } else {
      return product.price / 10
    }
  }
  
  useEffect(() => {

    fetchData()
    fetchStore()

    setTimeout(() => {
      runAnimations()
    }, 400)

    const onScroll = (event: any) => {
      const scrollTop = event.target.scrollTop


    }

    console.log(location.hash)
    if (location.hash != "") {
      setTimeout(() => {
        const hsh = document.querySelector(location.hash)
        if (hsh != null) {
          hsh.scrollIntoView({
            behavior: 'smooth',
            // block: 'start'
          })
        }
      }, 300)
    }

    document.getElementById("root")!.addEventListener('scroll', onScroll)

    return () => {
      document.getElementById("root")!.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className={styles.head_part}>
              <img className={styles.imgx + " " + styles.img1} src="/assets/images/home/head_part_img1.svg" />
              <img className={styles.imgx + " " + styles.img2} src="/assets/images/home/head_part_img2.svg" />
              <img className={styles.imgx + " " + styles.img3} src="/assets/images/home/head_part_img3.svg" />

              <h2>магазины натуральных продуктов<br/>от фермерских хозяйств Кубани</h2>
              <h1>Фермерские<br/>истории</h1>


              <div className={styles.catalog}>
                <a href="/catalog">Перейти в каталог</a>
              </div>

              <div className={styles.points}>
                <div>
                  <img src="/assets/images/home/point_1.svg" />
                  <p>Объединяем производителей<br/>экологичных продуктов<br/>и потребителей</p>
                </div>
                <div>
                  <img src="/assets/images/home/point_2.png" />
                  <p>Контролируем качество<br/>товаров и условия<br/>их производства</p>
                </div>
                <div>
                  <img src="/assets/images/home/point_3.svg" />
                  <p>Доставляем продукты<br/>напрямую с фермы<br/>к вашему порогу</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.section} id="about">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className={styles.h_part_1}>
                <h4>НАША ИСТОРИЯ</h4>

                <h3><b>Фермерские истории –</b><br/>это любимое семейное дело</h3>

                <p>
                  Все начиналось с небольшого фермерского хозяйства<br/>
                  на Кубани 25 лет назад. Наши первые уютные<br/>
                  магазины были созданы не просто как коммерческий<br/>
                  проект, а как воплощение семейной мечты.<br/><br/>
                  
                  Мы вложили всю душу, стремясь передать людям то,<br/>
                  что создается с любовью и заботой. Поэтому точно<br/>
                  знаем, что наша история успеха и преданности<br/>
                  качеству сможет найти свое продолжение в каждом<br/>
                  уголке, где зазвучит название «Фермерские истории».
                </p>

                <img className={styles.img1} src="/assets/images/home/section1/img1.jpeg" />
                <img className={styles.img2} src="/assets/images/home/section1/img2.jpeg" />
                <img className={styles.img3} src="/assets/images/home/section1/svgline.svg" />
              </div>
            </div>

            <div className="col-12">
              <h1>создаем продукты<br/><b>с заботой</b> о потребителях</h1>

              <div className={styles.hypes}>
                {shopStore.homeProducts.map(product => <div className={styles.product_card} style={{height: "360px"}}>
                  <div className={styles.product_card__title} style={{height: "73%"}}>{product.name}</div>
                  <div className={styles.product_card__hype}><img src="/assets/images/home/hype.svg" /></div>
                  <div className={styles.product_card__img}><img src={product.images.split(";")[0]} /></div>
                  <div className={styles.product_card__rate}><span><i className="fas fa-star"></i></span> 5.0</div>
                  <div className={styles.product_card__actions}>
                    {product.brand != null ? <a href={`/brands/${product.brand.id}`}>О производителе</a> : <></>}
                    <a href={`/catalog/${product.link_name}`}>{getFinalPrice(product)} <b><RubbleIcon /></b> <i className="fas fa-shopping-cart"></i></a>
                  </div>
                </div>)}
              </div>
            </div>

            <div className="col-12">
              <div className={styles.h_part_2}>
                <h5>ЦИТАТА ОСНОВАТЕЛЯ</h5>
                <h1><span>"</span>мы стремимся<br/>сделать <b>фермерские</b><br/><b>продукты</b> доступными<br/>для каждого</h1>
              
                <div className={styles.releaser}>
                  <div className={styles.r_avatar}><img src="/assets/images/home/rubashko.png" /></div>
                  <div className={styles.r_data}>
                    <div>Виталий рубашко</div>
                    <div>Основатель компании<br/>«Фермерские истории»</div>
                  </div>
                </div>

                <img className={styles.img1} src="/assets/images/home/vino.svg" />
                <img className={styles.img2} src="/assets/images/home/cruasan.svg" />
                <img className={styles.img3} src="/assets/images/home/vkuspri.svg" />
              </div>
            </div>

            <div className="col-12">
              <div className={styles.h_part_3} id="shops">
                <img className={styles.img1} src="/assets/images/home/adresses.svg" />
                <img className={styles.img2} src="/assets/images/home/map.svg" />

                <h1><b>адреса</b> магазинов</h1>

                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className={styles.shops}>
                      {shopStore.cities.map(city => city.shops.map(shop => <div className={styles.shops_item}>
                        <a href={`https://yandex.ru/maps/213/moscow/search/${encodeURI(shop.address.replace("<br/>", " "))}`} target="_blank" className={styles.shops_item__addr} dangerouslySetInnerHTML={{__html: `${shop.address}`}}></a>
                        <div className={styles.shops_item__time}>{shop.worktime}</div>
                      </div>))}
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <iframe src="https://yandex.ru/map-widget/v1/?lang=ru_RU&scroll=true&source=constructor-api&um=constructor%3A971bcc5578d1f08764a90d9996de1768f7b1809265180ddc56f5433b39a662e4"></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(Home)