import {LoadingOutlined} from "@ant-design/icons"
import { observer } from "mobx-react-lite"
import { FC, useContext, useState, useEffect } from "react"
import { Context } from "../../.."
import useWindowDimensions from "../../../core/hooks/useWindowDimensions"

import styles from "./OptionsModal.module.scss"

export interface OptionsModalProps {
  height?: string
  openned: boolean
  title: string
  children: any
  onClose: () => void

  done?: string 
  doneLoading?: boolean
  onDone?: () => void

  danger?: string 
  dangerLoading?: boolean
  onDanger?: () => void
}

const OptionsModal: FC<OptionsModalProps> = ({
  height = "", openned, title, children, onClose, done = null, doneLoading = false, onDone,
  danger = null, onDanger, dangerLoading = false
}) => {

  const { store } = useContext(Context)

  const { width } = useWindowDimensions()

  const [visible, setVisible] = useState(false)
  const [preVisible, setPreVisible] = useState(false)

  const openModal = () => {
    setPreVisible(true)
    setTimeout(() => {
      setVisible(true)
    }, 100)
  }

  const closeModal = () => {
    setVisible(false)
    setTimeout(() => {
      setPreVisible(false)
    }, 300)
  }

  const onKeydown = ({ key }: KeyboardEvent) => {
    switch (key) {
      case 'Escape':
        onClose()
        break
    }
  }

  useEffect(() => {
    console.log(openned)
    
    if (openned) {
      openModal()
    } else {
      closeModal()
    }
  }, [openned])

  useEffect(() => {
    document.addEventListener('keydown', onKeydown)
    return () => document.removeEventListener('keydown', onKeydown)
  }, [])

  return (
    <>
    <div className={styles.options_modal} style={{
        display: preVisible ? "flex" : "none",
        opacity: visible ? "1" : "0"
      }}>
        <div 
          className={styles.options_ph} 
          style={{
            display: preVisible ? "block" : "none",
            opacity: visible ? "1" : "0"
          }}
          onClick={(ev) => {
            ev.preventDefault()
            ev.stopPropagation()
            
            onClose()
          }}></div>
        <div className={styles.options_body + (height != "" ? " " + styles.heighted : "")}>
          <h2>{title}</h2>
          <div className={styles.options_body__close} onClick={() => onClose()}><i className="fas fa-xmark"></i></div>
        
          <div className={styles.options_body__content}>
            <div className={styles.options_body__content_f}>
              {children}
            </div>
          </div>

          <div className={styles.save}>
            {done != null ? <button onClick={() => {
              if (onDone != null) {
                onDone()
              }
            }}>{doneLoading ? <LoadingOutlined spin /> : done}</button> : <></>}
            {danger != null ? 
              <button className={styles.danger} onClick={() => {
                if (onDanger != null) {
                  onDanger()
                }
              }}>{dangerLoading ? <LoadingOutlined spin /> : danger}</button> : <></>}
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(OptionsModal)